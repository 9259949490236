<template>
    <v-container fluid >

        <v-img
                class="white--text align-top"
                height="200px"
                gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                :src="`/images/Consulta.jpg`"
            >
                <v-card-title justify-top>
                    Consulta externa
                </v-card-title>
            </v-img>

        <v-sheet elevation="2" :style='{"height":"100%"}'>
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view/>
            </transition>
        </v-sheet>
    </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>